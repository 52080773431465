<template>
  <div class="header">
    <van-search
      v-model="searchKey"
      show-action
      :placeholder="searchTip"
      ref="search"
      shape="round"
      @search="onSearch"
      @clear="onClear"
    >
      <template #left-icon>
        <div class="align-center">
          <van-image width="16" height="16" :src="searchIcon" />
        </div>
      </template>
      <template #left>
        <van-dropdown-menu id="selectDrop" active-color="#1989fa">
          <van-dropdown-item v-model="dropValue" :options="dropOption" @change="selectType" />
        </van-dropdown-menu>
      </template>
      <template #action>
        <div @click="() => onSearch(searchKey)" style="color: #1989fa">搜索</div>
      </template>
    </van-search>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'

import searchIcon from '@/assets/componentIcon/search.png'

export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    type: {
      required: true
    }
  },
  emits: ['update:modelValue', 'update:type', 'selectType', 'search', 'clear'],
  setup(props, { emit }) {
    const dropOption = [
      { text: '公司', value: 0 },
      { text: '车辆', value: 1 }
    ]
    const searchKey = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const search = ref(null) // search输入框DOM
    onMounted(() => {
      search.value.focus()
    })
    const selectType = value => {
      // searchKey.value = ''
      emit('update:type', value)
      emit('selectType', value)
    }

    const dropValue = ref(0)

    const searchTip = computed(() => (dropValue.value === 0 ? '请输入至少2位关键字' : '请输入至少4位关键字'))

    const router = useRouter()

    const onCancel = () => {
      router.go(-1)
    }

    function onSearch(value) {
      searchKey.value = value.trim()
      const reg = new RegExp(/[`~!#$%^&*()_\-+=<>?:"{}|,./;'\\[\]]/im)
      value = value.trim()
      if (!value) {
        Toast('请输入搜索内容')
        return
      }
      if (reg.test(value)) {
        Toast('搜索内容中包括特殊字符，请检查后重新操作')
        return
      }
      if (value.length < 2 && dropValue.value === 0) {
        Toast('请输入最少2位关键字进行公司搜索')
        return
      }
      if (value.length < 4 && dropValue.value === 1) {
        Toast('请输入最少4位关键字进行车辆搜索')
        return
      }
      emit('search', value)
    }

    function onClear() {
      emit('clear')
    }

    return {
      searchIcon,
      dropOption,
      search,
      dropValue,
      searchKey,
      searchTip,
      selectType,
      onSearch,
      onClear,
      onCancel
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  box-shadow: 0 1px 2px #ebecf0;
  z-index: 20;
  #selectDrop {
    width: 60px;
    border-radius: 4px;
    margin: 0 2px;
    padding: 10px 4px;
    :deep .van-dropdown-menu__bar {
      height: 34px;
      box-shadow: none;
    }
  }
}
</style>

<template>
  <div class="flex-main scroll-y wrapper" v-if="list.length !== 0">
    <div class="row" v-for="(item, index) in list" :key="index">
      <div class="company">{{ item.company.companyName }}</div>
      <div v-for="el in item.carList" :key="el.carNumber" @click="clickHandler(el)" class="car-info">
        <div class="car-info__status">
          <img :src="el.imgSrc" style="margin: 0; height: 50px" />
        </div>
        <div class="car-info__number">{{ el.carNumber }}</div>
        <div class="car-info__arrow">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    clickHandler: {
      type: Function,
      default(item) {
        return item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 8px 0;
  .row {
    box-sizing: border-box;
    width: 100vw;
    padding: 8px 0;
    background: #fff;
    .company {
      padding: 6px 8px;
      border-bottom: 1px solid #f3f3f3;
    }
    .car-info {
      display: flex;
      align-items: center;
      height: 42px;
      background-color: #f6f7f9;
      padding: 8px;
      &__status {
        font-size: 32px;
      }
      &__number {
        flex-grow: 1;
        font-size: 16px;
      }
      &__arrow {
        width: 70px;
        font-size: 16px;
        text-align: center;
      }
    }
  }
  .row::after {
    position: absolute;
    bottom: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #f1f1f1;
  }
  .row:last-child::after {
    height: 0;
  }
}
</style>

<template>
  <div class="wrap column">
    <div class="wrap__status-bar"></div>
    <Header v-model="searchKey" v-model:type="dropValue" @search="onSearch" />

    <Empty v-if="noResult" />
    <!-- 公司搜索结果展示区域 -->
    <CompanyList :list="companyResult" :clickHandler="companyShowDetails" />

    <!-- 车辆搜索结果展示区域 -->
    <CarList :list="carResult" :clickHandler="goToDetail" />
  </div>
</template>
<script>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/request'
import onlineSrc from '@/assets/Orientation/onlineCar.png'
import offlineSrc from '@/assets/Orientation/offlineCar.png'

import Header from './components/search-header.vue'
import Empty from './components/empty.vue'
import CompanyList from './components/company-list.vue'
import CarList from './components/car-list.vue'
export default {
  components: { Header, Empty, CompanyList, CarList },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const dropValue = ref(0) // 下拉框选中内容

    const searchKey = ref('')
    const noResult = ref(false) //未搜索到结果给出提示

    const companyResult = ref([])
    const carResult = ref([])

    // 切换选中类型，清空内容
    // const selectType = () => {
    //   companyResult.value = []
    //   carResult.value = []
    // }

    function onSearch(value) {
      try {
        searchInfo(value, dropValue.value)
      } catch (error) {
        console.warn(error)
      }
    }
    function companyShowDetails(item) {
      router.push({
        name: 'CarList',
        replace: true,
        params: { id: item.id },
        query: {
          ...route.query,
          companyName: item.name
        }
      })
    }
    function goToDetail(carInfo) {
      store.commit('updateCarInfo', { carNumber: carInfo.carNumber, deviceId: carInfo.deviceId })
      router.push({
        name: 'CarDetail',
        replace: true,
        query: {
          title: carInfo.carNumber,
          carNumber: carInfo.carNumber
        }
      })
    }
    async function searchInfo(value, flag) {
      value = value.toUpperCase()
      let companyInfo = [],
        carInfo = []
      if (flag === 0) {
        const companyData = await http.post('company/queryCompanyInfoListV2.html', { companyName: value, isSearch: 1 })
        companyInfo = companyData.data
      } else if (flag === 1) {
        const carData = await http.post('company/queryCarInfoListV3.html', { carNumber: value, isSearch: 1 })
        carInfo = carData.data
        carInfo.forEach(car => {
          // 根据是否在线修改展示图片
          car.carList = car.carList.map(el => {
            if (el.icon.indexOf('offline') >= 0) {
              el.imgSrc = offlineSrc
            } else {
              el.imgSrc = onlineSrc
            }
            return el
          })
          // 在线车辆放在前面
          car.carList.sort((a, b) => {
            return a.icon.length - b.icon.length
          })
        })
      }
      if (carInfo.length === 0 && companyInfo.length === 0) {
        noResult.value = true
      } else {
        noResult.value = false
      }
      companyResult.value = companyInfo
      carResult.value = carInfo
    }
    return {
      searchKey,
      dropValue,
      // selectType,
      noResult,
      carResult,
      onSearch,
      companyResult,
      companyShowDetails,
      goToDetail
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.wrap {
  height: calc(var(--vh, 1vh) * 100);
  background-color: #f6f7f9;
  color: $font-color;
  &__status-bar {
    height: 44px;
    background-color: #fff;
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  .wrap {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  }
}
</style>

<template>
  <div class="flex-main scroll-y wrapper" v-if="list.length !== 0">
    <div class="title">
      <div>公司信息</div>
    </div>
    <div class="row text-cell" v-for="el in list" :key="el.id" @click="clickHandler(el)">
      <div class="row-content flex-main">
        <div class="name">{{ el.name }}</div>
      </div>
      <p class="row-icon"><van-icon name="arrow" /></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    clickHandler: {
      type: Function,
      default(item) {
        return item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 8px 0;
  .title {
    padding: 6px 12px;
    background: #fff;
    border-bottom: 0.01rm solid #f3f3f3;
  }

  .row {
    background-color: #fff;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    &-content {
      padding: 12px 0;
      border-bottom: 1px solid #f3f3f3;
      .name {
        font-size: 14px;
        margin-bottom: 6px;
      }
    }
    &-icon {
      color: #c8c9cc;
    }
  }
}
</style>
